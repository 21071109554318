import { createFeatureSelector, createSelector } from '@ngrx/store';
import { uniqBy } from 'lodash';

import { Channel } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('activeChannels');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: string) =>
  createSelector(
    selectAllItems,
    (items: Channel[]) => items && items.find((p) => p.id === +id),
  );

export const selectWithoutVirtualChannel = createSelector(
  selectAllItems,
  (items: Channel[]) =>
    items && items.filter((channel) => !channel?.is_virtual_channel),
);

export const selectPropertiesChannels = (channelId: string) =>
  createSelector(
    selectById(channelId),
    (channel: Channel) => channel?.properties,
  );

export const selectChannelsColors = createSelector(selectAllItems, (channels) =>
  channels?.reduce<Record<number, string>>((colors, channel) => {
    colors = { ...colors, [channel.id]: channel.color };
    return colors;
  }, {}),
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectbyPropertyId = createSelector(
  selectAllItems,
  (items: Channel[]): { [propertyId: number]: Channel[] } => {
    return items.reduce((acc, channel) => {
      const { properties } = channel;
      properties.forEach((propertyId) => {
        if (!acc[+propertyId]) {
          acc = {
            ...acc,
            [+propertyId]: [],
          };
        }
        acc = {
          ...acc,
          [+propertyId]: uniqBy([...acc[+propertyId], channel], 'id'),
        };
      });
      return acc;
    }, {});
  },
);
