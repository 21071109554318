import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) => {
    return fromState.featureAdapter.setAll(
      items.map((channel) => {
        const preferredColor =
          state.preferredColors && state.preferredColors[channel.id];

        return {
          ...channel,
          color: preferredColor || channel.color,
          default_color: channel.color,
        };
      }),
      {
        ...state,
        isLoading: false,
        error: null,
      },
    );
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.setPreferredColors, (state, { preferredColors }) =>
    fromState.featureAdapter.updateMany(
      Object.entries(preferredColors || {}).map(([channelId, color]) => {
        return { id: +channelId, changes: { color } };
      }),
      { ...state, preferredColors },
    ),
  ),

  on(fromActions.resetState, () => fromState.initialState),
);

export function activeChannelsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
