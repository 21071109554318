import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ChannelsService } from '../../services';
import { RootState } from '../root-state';
import { UserPreferencesStoreSelectors } from '../user-preferences-store';

import * as fromActions from './actions';

@Injectable()
export class ChannelsStoreEffects {
  private channelsColorsUserPreferences$ = this.store.pipe(
    select(
      UserPreferencesStoreSelectors.selectUserPreferencesDataByCategory(
        'channelsColors',
      ),
    ),
  );

  constructor(
    private dataService: ChannelsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private store: Store<RootState>,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties, withoutAccommodations }) =>
        this.dataService
          .loadActive(properties, {
            with_accommodation: +!withoutAccommodations,
          })
          .pipe(
            map((response) =>
              fromActions.loadSuccess({
                items: response,
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );

  preferredColors$ = createEffect(() =>
    this.channelsColorsUserPreferences$.pipe(
      map((preferredColors) =>
        fromActions.setPreferredColors({ preferredColors }),
      ),
    ),
  );
}
