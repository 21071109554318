import { createAction, props } from '@ngrx/store';

import { Channel } from '../../models';

export const loadRequest = createAction(
  '[Active Channels] Load Request',
  props<{
    properties: number[];
    withoutAccommodations: boolean;
  }>(),
);

export const loadSuccess = createAction(
  '[Active Channels] Load Success',
  props<{ items: Channel[] }>(),
);

export const loadFailure = createAction(
  '[Active Channels] Load Failure',
  props<{ error: any }>(),
);

export const setPreferredColors = createAction(
  '[Active Channels] Set Preferred Colors',
  props<{ preferredColors: Record<number, string> }>(),
);

export const resetState = createAction('[Active Channels] Reset State');
